import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Adieu La Poésie" />
    <h3 className='underline-title'>Adieu La Poésie</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Poète<br />
        Sois désormais insensible à toute inspiration<br />
        Refus aux Muses de se servir de ta voix<br />
        Pour parler aux terriens nés hors de route<br />
        Non seulement aveugles mais aussi sourds<br />
        Le Galiléen ne l'a-t-il pas assez dit?<br />
        "Ils ont des oreilles mais n'entendent pas"<br />
        "Ils ont des yeux mais ne voient pas"<br />
      </p>
      <p>
        Qui dirait mieux?<br />
      </p>
      <p>
        Poète<br />
        Ta mission est terminée<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
